import React from "react"

import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import Image from "gatsby-image"

export default function Banner({ image, title }) {
  return (
    <section className="relative">
      <div className="relative">
        <div className="absolute inset-x-0" />
        <div className="">
          <div className="relative sm:overflow-hidden">
            <div className="absolute inset-0">
              <Image
                fluid={image}
                alt={title}
                className="h-full w-full object-fit brightness-120	"
              />
              <div className="absolute inset-0" />
            </div>
            <div className="relative  px-4 py-16 sm:px-6 sm:py-24 lg:py-72 lg:px-8">
              <h1 className="text-center text-4xl tracking-tight sm:text-5xl lg:text-6xl pt-12 pb-10">
                <span className="block text-white drop-shadow-lg glow">
                  {title}
                </span>
              </h1>
              {/*<p className="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">*/}
              {/*	Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt*/}
              {/*	amet fugiat veniam occaecat fugiat aliqua.*/}
              {/*</p>*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
