import * as React from "react"
import Header from "../components/header"
import Banner from "../components/banner"
import Stats from "../components/stats"
import Footer from "../components/footer"
import Seo from "../components/seo"
import OtherPanels from "../components/home/other-panels"
import { graphql } from "gatsby"
import Image from "gatsby-image"

const IndexPage = ({ data }) => {
  const image =
    data?.wpPage?.featuredImage?.node?.localFile?.childImageSharp?.fluid
  const title = data.wpPage.title

  const otherPanelsData = data.wpPage.homeImages

  const otherPanels = {
    whoweare: {
      title: otherPanelsData.whoWeAre.title,
      image: (
        <Image
          fluid={otherPanelsData.whoWeAre.localFile?.childImageSharp?.fluid}
          alt={otherPanelsData.whoWeAre.title}
          className="object-fit h-80 md:h-auto"
        />
      ),
      imageCover: (
        <Image
          fluid={otherPanelsData.whoWeAre.localFile?.childImageSharp?.fluid}
          alt={otherPanelsData.whoWeAre.title}
          className="object-fit h-80 md:h-auto"
        />
      ),
      description: "",
    },
    projects: {
      title: otherPanelsData.projects.title,
      direction: "flex-row-reverse",
      image: (
        <Image
          fluid={otherPanelsData.projects.localFile?.childImageSharp?.fluid}
          alt={otherPanelsData.projects.title}
          className="object-fit h-80 md:h-auto"
        />
      ),
      imageCover: (
        <Image
          fluid={otherPanelsData.projects.localFile?.childImageSharp?.fluid}
          alt={otherPanelsData.projects.title}
          className="object-fit h-80 md:h-auto"
        />
      ),
      description: "",
    },
    latest: {
      title: otherPanelsData.latest.title,
      image: (
        <Image
          fluid={otherPanelsData.latest.localFile?.childImageSharp?.fluid}
          alt={otherPanelsData.latest.title}
          className="object-fit h-80 md:h-auto"
        />
      ),
      imageCover: (
        <Image
          fluid={otherPanelsData.latest.localFile?.childImageSharp?.fluid}
          alt={otherPanelsData.latest.title}
          className="object-fit h-80 md:h-auto"
        />
      ),
      description: "",
    },
  }

  return (
    <>
      <Seo title={"Philippine Earth Justice Center"} />
      <div className="bg-white">
        <main>
          <div>
            <Header />
            <Banner image={image} title={title} />
            <Stats />
            <OtherPanels data={otherPanels} />
            {/*<Features></Features>*/}
            {/*<Logo></Logo>*/}
            <Footer />
          </div>
        </main>
      </div>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query MyQuery {
    wpPage(id: { eq: "cG9zdDo2" }) {
      id
      title
      homeImages {
        latest {
          title
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
        projects {
          title
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
        whoWeAre {
          title
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`
