import CountUp from "react-countup"
import React from "react"
import VisibilitySensor from "react-visibility-sensor"

export default function Stat({ value, sub }) {
  return (
    <div className="flex flex-col p-2 md:p-6 text-center">
      <dd className="text-6xl md:text-6xl lg:text-8xl font-semibold">
        <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
          {({ isVisible }) => {
            return (
              <span>
                {isVisible ? (
                  <CountUp
                    start={0}
                    end={value}
                    delay={0}
                    duration={5}
                    useEasing={true}
                  />
                ) : (
                  value
                )}
                +
              </span>
            )
          }}
        </VisibilitySensor>
      </dd>
      <dt className="mt-2 text-2xl md:text-2xl lg:text-4xl leading-10 text-gray-600 font-medium">
        {sub}
      </dt>
    </div>
  )
}
