import React from "react"
import Stat from "./home/stat"

export default function Stats() {
  return (
    <div className="pt-4">
      {/*<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">*/}
      {/*	<div className="max-w-4xl mx-auto text-center">*/}
      {/*		<h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">*/}
      {/*			Trusted by the communities and partners.*/}
      {/*		</h2>*/}
      {/*		<p className="mt-3 text-xl text-gray-500 sm:mt-4">*/}
      {/*			Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus repellat laudantium.*/}
      {/*		</p>*/}
      {/*	</div>*/}
      {/*</div>*/}
      <div className="mt-2 pb-2 bg-white lg:pb-16 lg:pt-16">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="mx-auto">
            <dl className=" sm:grid sm:grid-cols-3">
              <Stat value={50} sub={"Communities Reached"} />
              <Stat value={30} sub={"Partners"} />
              <Stat value={100} sub={"Cases Built"} />
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}
