import MissionVision from "../who-we-are/mission-vision"
import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function OtherPanels({ data }) {
  return (
    <>
      <div className="relative  md:inline max-w-7xl">
        <a href="/who-we-are">
          <MissionVision isCoverImage={true} data={data.whoweare} />
        </a>
        <a href="/our-projects">
          <MissionVision isCoverImage={true} data={data.projects} />
        </a>
        <a href="/latest-news">
          <MissionVision isCoverImage={true} data={data.latest} />
        </a>
      </div>
    </>
  )
}
